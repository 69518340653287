import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { UserWithFirm, useUser } from './useUser';
import useLocalUser from 'hooks/useLocalUser';
import AssetAccess from 'components/asset-access';
import useTerms from './useTerms';
import { useLocation } from 'react-router-dom';
import Analytics from './Analytics';
import { LoadingMessage } from 'components/common/LoadingMessage';
import { useNavigate } from 'react-router';
import { FlashMessageContextProvider } from './FlashMessageContextProvider';
import { TabContextProvider } from './TabContextProvider';
import { BookmarkContextProvider } from './BookmarkContextProvider';
import { TermsAndConditionsQuery } from '__generated__/graphql';

export interface AppContextValue {
  user?: UserWithFirm;
  localUser: any;
  terms?: TermsAndConditionsQuery['contentfulSimplePage'] | null;
  setShowTerms?: (showTerms: boolean) => void;
  showTerms?: boolean;
}

export const AppContext = React.createContext<AppContextValue>({
  localUser: null,
  user: undefined,
  terms: null,
  setShowTerms: () => {},
  showTerms: false,
});

const LoggedIn: React.FC<{ localUser: any; children: React.ReactNode }> = ({
  localUser,
  ...props
}) => {
  const location = useLocation();
  const user = useUser();
  const terms = useTerms();
  const [showTerms, setShowTerms] = React.useState(false);

  React.useEffect(() => {
    const acceptanceOutdated = dayjs(
      user?.termsConditionsLastAcceptance!,
    ).isBefore(dayjs(terms?.updatedDate));
    setShowTerms(acceptanceOutdated);
  }, [user, terms]);

  React.useLayoutEffect(() => {
    if (!showTerms && localUser.postLoginRedirect) {
      window.location = localUser.postLoginRedirect;
    }
  }, [showTerms, localUser.postLoginRedirect]);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.needsEmailUpdate && location.pathname !== '/updateaccount') {
      navigate('/updateaccount', { state: { from: location } });
    }
  }, [user, location, navigate]);

  return (
    <AppContext.Provider
      value={{
        user,
        localUser,
        terms,
        setShowTerms,
        showTerms,
      }}
    >
      <AssetAccess>
        <Analytics>
          <BookmarkContextProvider>
            <TabContextProvider>
              <FlashMessageContextProvider>
                {props.children}
              </FlashMessageContextProvider>
            </TabContextProvider>
          </BookmarkContextProvider>
        </Analytics>
      </AssetAccess>
    </AppContext.Provider>
  );
};

const AUTH_BLACKLIST = ['/register/creating', '/logout'];

export default function AppContextProvider(
  props: React.PropsWithChildren<{}>,
): React.ReactElement {
  const [loading, localUser] = useLocalUser();
  const { pathname } = useLocation();

  const authorized = React.useMemo(
    () => localUser?.authorized && !AUTH_BLACKLIST.includes(pathname),
    [localUser, pathname],
  );

  if (loading) {
    return <LoadingMessage />;
  }

  return authorized ? (
    <LoggedIn localUser={localUser}>{props.children}</LoggedIn>
  ) : (
    <AppContext.Provider
      value={{
        localUser,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}
