import React from 'react';
import PropTypes from 'prop-types';
import classSet from 'classnames';
import { FormCheckbox } from '../form_checkbox';
import { InputComponent } from './input';
import { formContext } from '../index';
import { FormLabel } from '../FormLabel';
import styled from 'styled-components';
import { CEB_COLOR } from 'app-constants/CEB_COLOR';

interface FormInputProps {
  name?: string;
  type?: 'text' | 'password' | 'search' | 'email' | 'number' | 'checkbox';
  placeholder?: string;
  className?: string;
  label?: string;
  validator?: ((value: string) => boolean) | RegExp;
  validateOn?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.RefObject<any>;
  [key: string]: any; // for other props being spread
}

export function FormInput(props: FormInputProps) {
  const inputHookRef = React.useRef<HTMLInputElement>(null);
  const inputRef = props.inputRef || inputHookRef;
  const context = React.useContext(formContext);
  const { setFormState } = context;

  const [valid, setValid] = React.useState(true);
  const { name, validator } = props;

  const validate = React.useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      if (validator && inputRef.current) {
        const value = inputRef.current.input
          ? inputRef.current.input.value
          : inputRef.current.value;
        const excessive =
          inputRef.current.maxLength !== -1
            ? value.length > inputRef.current.maxLength
            : false;
        const valid =
          typeof validator === 'function'
            ? validator(value) && !excessive
            : validator.test(value) && !excessive;
        setValid(valid);
        setFormState?.(name, valid);
      } else {
        setValid(true);
        setFormState?.(name, true);
      }
    },
    [name, validator, inputRef, setFormState],
  );

  React.useEffect(() => {
    validate();
  }, [props.validateOn, validate]);

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        e.persist();
        props.onChange(e);
      }
      validate(e);
    },
    [props.onChange, validate],
  );

  const classNames = Object.assign(
    {
      invalid: !valid,
    },
    props.className
      ? {
          [props.className]: true,
        }
      : {},
  );

  return (
    <StyledFormInput className={classSet(classNames)}>
      {props.type === 'checkbox' ? (
        <FormCheckbox
          {...props}
          inputRef={inputRef}
          handleInputChange={handleInputChange}
        />
      ) : props.label ? (
        <FormLabel {...props} className={classSet(classNames)}>
          <InputComponent
            {...props}
            inputRef={inputRef}
            handleInputChange={handleInputChange}
          />
        </FormLabel>
      ) : (
        <InputComponent
          {...props}
          inputRef={inputRef}
          handleInputChange={handleInputChange}
        />
      )}
    </StyledFormInput>
  );
}

const StyledFormInput = styled.div`
  margin-bottom: 5px;
  text-align: left;
  &.invalid {
    input {
      color: ${CEB_COLOR('TUSCANY')};
      border-color: ${CEB_COLOR('TUSCANY')};
    }
  }

  input,
  select {
    border: 1px solid;
    box-sizing: border-box;
    font-family: 'Zilla Slab', serif;
    font-size: inherit;
  }
  input[type='text'],
  input[type='search'],
  input[type='email'],
  input[type='password'] {
    padding: 5px 10px;
    width: 100%;
  }
`;

FormInput.propTypes = {
  /** name of input */
  name: PropTypes.string,
  /** type of form input */
  type: PropTypes.oneOf([
    'text',
    'password',
    'search',
    'email',
    'number',
    'checkbox',
  ]),
  /** placeholder text */
  placeholder: PropTypes.string,
};

FormInput.defaultProps = {
  type: 'text',
  placeholder: '',
};
