import React from 'react';
import styled from 'styled-components';

interface FormLabelProps {
  className?: string;
  hint?: string;
  children?: React.ReactNode;
  label: string;
  required?: boolean;
  readonly?: boolean;
}

export function FormLabel({
  className = '',
  hint = '',
  children,
  label,
  required,
  readonly = false,
  ...props
}: FormLabelProps): JSX.Element {
  return (
    <StyledFormLabel className={className} data-readonly={readonly} {...props}>
      <span className={`label-text ${className}`}>
        {label}
        {!required ? (
          <span className={`label-required ${className}`}>optional</span>
        ) : null}
      </span>
      {hint ? <span className={`hint-text ${className}`}>{hint}</span> : null}
      {children}
    </StyledFormLabel>
  );
}

const StyledFormLabel = styled.label`
  .label-text {
    display: inline-block;
    margin-bottom: 0.72em;
  }
  .label-required {
    color: var(--COLOR_HAVELOCK_BLUE);
    float: right;
    font-size: 0.8em;
    font-weight: bold;
    margin-top: 0.2em;
    text-transform: uppercase;
  }
  &[data-readonly] {
    &:hover {
      cursor: default;
    }
  }
`;
