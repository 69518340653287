import { gql } from '@apollo/client';
import UserFragments from 'graphql/fragments/user';

export const GET_CONTENTFUL_PRACTICE_AREAS = gql`
  query getAllContentfulPracticeAreas {
    contentfulPracticeArea(id: "2IJw5gDFab7ZY5e7dNpLyL") {
      ...ContentfulPracticeAreas
    }
  }
  ${UserFragments.ContentfulPracticeAreas}
`;

export const GET_DAILY_NEWS_AREAS = gql`
  query getDailyNewsPracticeAreas {
    contentfulPracticeArea(id: "2IJw5gDFab7ZY5e7dNpLyL") {
      ...ContentfulPracticeAreas
    }
    user {
      ...UserPracticeAreas
    }
  }
  ${UserFragments.UserPracticeAreas}
  ${UserFragments.ContentfulPracticeAreas}
`;
