import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useSummary } from 'hooks/useSummary';
import styled from 'styled-components';
import { INLINES } from '@contentful/rich-text-types';
import { TrackedLink } from 'components/common/TrackedLink';

const INLINE_INTERNAL_LINK_RENDERNODE = {
  [INLINES.HYPERLINK]: (node: any) => {
    const isInternal = node.data.uri.match(/research\.ceb\.com/);
    return isInternal ? (
      <TrackedLink
        to={node.data.uri.replace(/https*:\/\/research.ceb.com/, '')}
      >
        {node?.content && (
          <ContentfulContent content={{ json: { content: node.content } }} />
        )}
      </TrackedLink>
    ) : (
      <a href={node.data.uri}>
        <ContentfulContent content={{ json: { content: node.content } }} />
      </a>
    );
  },
};

interface ContentfulContentProps {
  renderNodeOptions?: any;
  document?: any;
  content?: any;
}

export function ContentfulContent({
  renderNodeOptions,
  document,
  content,
}: ContentfulContentProps) {
  const contentfulDocument = document || {
    nodeType: 'document',
    data: {},
    content: content.json.content,
  };

  const options = React.useMemo(() => {
    return {
      renderNode: {
        ...INLINE_INTERNAL_LINK_RENDERNODE,
        ...(renderNodeOptions || {}),
      },
    };
  }, [renderNodeOptions]);

  return documentToReactComponents(contentfulDocument, options);
}

interface ContentfulSummaryProps {
  summaryLength?: number;
  children?: React.ReactNode;
  document?: any;
  content?: any;
}

export const ContentfulSummary = ({
  summaryLength = 300,
  children,
  document,
  content,
}: ContentfulSummaryProps) => {
  const contentFul = React.useRef(null);
  const [summary] = useSummary(contentFul, 'div > p', summaryLength);
  return (
    <>
      <StyledContentfulSummary className="contentful" ref={contentFul}>
        <ContentfulContent document={document} content={content} />
        {children}
      </StyledContentfulSummary>
      {summary ? (
        <StyledContentfulSummary className="contentful-summary">
          <p>{summary}...</p>
          {children}
        </StyledContentfulSummary>
      ) : null}
    </>
  );
};

interface RawContentfulSummaryProps {
  summaryLength?: number;
  children?: React.ReactNode;
  document?: any;
  content?: any;
}

export const RawContentfulSummary = ({
  summaryLength = 300,
  children,
  document,
  content,
}: RawContentfulSummaryProps) => {
  const contentFul = React.useRef(null);
  const [summary] = useSummary(contentFul, 'div > p', summaryLength);
  return (
    <>
      <StyledContentfulSummary className="contentful" ref={contentFul}>
        <ContentfulContent document={document} content={content} />
        {children}
      </StyledContentfulSummary>
      {summary ? summary : null}
    </>
  );
};

const StyledContentfulSummary = styled.div`
  &.contentful {
    display: none;
  }
`;
