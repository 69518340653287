import React from 'react';
import useChangeOptionFilters from 'hooks/useChangeOptionFilters';
import useQueryString from 'hooks/useQueryString';
import getOr from 'lodash/fp/getOr';
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useSuspenseQuery,
} from '@apollo/client';

export function useFilters<
  TData,
  TVariables extends OperationVariables,
  ItemType,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  queryStringVariable: string,
  // itemsPath: string,
  itemsSelector: (data: TData) => ItemType[],
) {
  const queryString = useQueryString();
  const { data } = useSuspenseQuery<TData, TVariables>(query);
  const items = itemsSelector(data as TData); //getOr<ItemType[]>([])(itemsPath)(data as any);

  const filterIds = React.useMemo(
    () =>
      (queryString &&
        queryString[queryStringVariable] &&
        queryString[queryStringVariable].split(',')) ||
      [],
    [queryString, queryStringVariable],
  );

  const filters = React.useMemo(
    () =>
      filterIds.reduce(
        (filters, filter) => {
          filters[filter] = true;
          return filters;
        },
        {} as Record<string, boolean>,
      ),
    [filterIds],
  );

  const changeOptionFilters = useChangeOptionFilters(queryStringVariable);

  const onOptionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const filterValue = e.target.name;
      filters[filterValue] = e.target.checked;
      changeOptionFilters(filters);
    },
    [filters, changeOptionFilters],
  );

  return [items, filters, onOptionChange] as const;
}
