import React, { useCallback, useMemo, useState } from 'react';
import StyledFilterOption from 'components/common/search/sidebar/filters/StyledFilterOption';
import styled from 'styled-components';

export interface FilterOptionFilter {
  filterValue: string;
  name: string;
  children?: FilterOptionFilter[];
  linkedFrom?: any;
  indeterminate?: boolean;
  checked?: boolean;
}

export interface FilterOptionProps {
  filter: FilterOptionFilter;
  onOptionChange?: React.ChangeEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
  className?: string;
  depth?: number;
  collapseDepth?: number;
}

export const FilterOption = ({
  filter,
  onOptionChange,
  children,
  className,
  collapseDepth: collapse,
  depth = 0,
}: FilterOptionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpand: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    () => setExpanded(expanded => !expanded),
    [],
  );
  const expandable = useMemo(
    () => collapse !== undefined && depth >= collapse,
    [collapse, depth],
  );
  const canBeExpanded = useMemo(
    () => expandable && filter.children && filter.children.length > 0,
    [expandable, filter],
  );

  return (
    <StyledFilterOption className={`option ${className || ''}`}>
      <label>
        <input
          type="checkbox"
          name={filter.filterValue}
          onChange={onOptionChange}
          checked={filter.checked}
          ref={elem =>
            elem && (elem.indeterminate = filter.indeterminate === true)
          }
        />
        {canBeExpanded ? (
          <StyledExpandButton
            onClick={handleExpand}
            aria-label="expand section"
          >
            <i className={`fas fa-caret-${expanded ? 'down' : 'right'}`} />
          </StyledExpandButton>
        ) : null}
        {filter.name}
      </label>
      {children}
      <div
        style={{
          display: !expandable || (expandable && expanded) ? 'block' : 'none',
        }}
      >
        {filter.children
          ? filter.children.map((filter, key) => (
              <FilterOption
                key={key}
                filter={filter}
                onOptionChange={onOptionChange}
                collapseDepth={collapse}
                depth={depth + 1}
              />
            ))
          : null}
      </div>
    </StyledFilterOption>
  );
};

const StyledExpandButton = styled.button`
  margin-right: 0.25em;
`;
