import React from 'react';
import styled from 'styled-components';
import {
  Author,
  CleContributor,
  ContentfulContributor,
  Maybe,
} from '__generated__/graphql';

interface AuthorNameProps {
  author: Maybe<CleContributor | ContentfulContributor | Author>;
  index: number;
  totalAuthors: number;
}

export const AuthorName: React.FC<AuthorNameProps> = ({
  author,
  index,
  totalAuthors,
}) => {
  if (!author) {
    return null;
  }

  return (
    <StyledAuthorName>
      {index > 0 && index === totalAuthors - 1 && (
        <span className="conjoiner">and</span>
      )}
      {author?.webDisplayName}
      {index + 1 < totalAuthors - 1 && <span className="separator">,</span>}
    </StyledAuthorName>
  );
};

const StyledAuthorName = styled.dd`
  font-weight: bold;
  .conjoiner {
    margin: 0 4px;
  }
  .separator {
    margin: 0 4px 0 0;
  }
`;
