import React from 'react';
import { FilterOption } from 'components/practitioner/FilterOption';
import { YourPracticeAreas } from './YourPracticeAreas';
import { useFilters } from './useFilters';

import { StyledExpandableSection } from './StyledExpandableSection';
import { DocumentNode } from 'graphql';
import { OperationVariables, TypedDocumentNode } from '@apollo/client';

export interface CLELibraryPracticeAreaFiltersProps {
  query: DocumentNode | TypedDocumentNode<unknown, OperationVariables>;
  linkedFrom?: any;
  userPracticeAreas?: any[];
}

export function CLELibraryPracticeAreaFilters({
  query,
  linkedFrom,
  userPracticeAreas,
}: CLELibraryPracticeAreaFiltersProps) {
  const [practiceAreaFilters, practiceAreas, onPracticeAreaOptionChange] =
    useFilters(
      query,
      'practiceAreas',
      (data: any) =>
        data.contentfulPracticeArea.practiceAreaSubtopicsCollection.items,
    );

  const userPracticeAreaIds = React.useMemo(
    () => (userPracticeAreas ? userPracticeAreas.map(area => area.sys.id) : []),
    [userPracticeAreas],
  );

  return (
    <StyledExpandableSection
      name="Practice Area"
      border={false}
      defaultExpanded
      cache
    >
      {userPracticeAreas && (
        <YourPracticeAreas
          userPracticeAreas={userPracticeAreas}
          practiceAreas={practiceAreas}
        />
      )}
      {practiceAreaFilters
        .filter(
          (item: any) =>
            item.linkedFrom[linkedFrom]?.total > 0 &&
            !userPracticeAreaIds.includes(item.sys.id),
        )
        .map((item: any) => (
          <FilterOption
            key={item.sys.id}
            filter={{
              filterValue: item.sys.id,
              name: item.name,
              checked: practiceAreas[item.sys.id] === true,
            }}
            onOptionChange={onPracticeAreaOptionChange}
          />
        ))}
    </StyledExpandableSection>
  );
}
