import React, { ComponentPropsWithRef, useMemo } from 'react';
import { convertDomToReactElements } from './convertDomToReactElements';
import removeChildren from 'util/removeChildren';

export interface ElementRendererProps
  extends Omit<ComponentPropsWithRef<'div'>, 'content'> {
  content?: HTMLDivElement;
  afterUpdate?: () => void;
}

export const ElementRenderer = ({
  content,
  afterUpdate,
  ...props
}: ElementRendererProps) => {
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (content) {
      removeChildren(elementRef.current);
      elementRef.current?.appendChild(content);
      afterUpdate && afterUpdate();
    }
  }, [content, afterUpdate]);

  return <div ref={elementRef} {...props} />;
};

export interface ReactElementRendererProps extends ElementRendererProps {
  transformer: any;
}

export const ReactElementRenderer = React.forwardRef<
  HTMLDivElement,
  ReactElementRendererProps
>(({ content, afterUpdate, transformer, ...props }, ref) => {
  React.useLayoutEffect(() => {
    afterUpdate && afterUpdate();
  }, [content, afterUpdate]);

  const Component = useMemo(
    () => content && convertDomToReactElements(content, transformer),
    [content, transformer],
  );

  return (
    <div {...props} ref={ref}>
      {Component}
    </div>
  );
});

ReactElementRenderer.displayName = 'ReactElementRenderer';
