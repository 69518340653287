import React from 'react';
import { getReactAttributes } from './getReactAttributes';

let index = 0;

export function convertDomToReactElements(
  domElement: HTMLElement,
  transformer?: (
    el: HTMLElement,
    children: React.ReactNode[],
  ) => React.ReactNode,
) {
  const { tagName, attributes, childNodes } = domElement;

  const reactChildren: React.ReactNode[] = [...childNodes].map(child => {
    if (child.nodeType === Node.ELEMENT_NODE) {
      return convertDomToReactElements(child as HTMLElement, transformer);
    } else if (child.nodeType === Node.TEXT_NODE) {
      return child.textContent;
    }
    return null;
  });

  const transformedComponent =
    transformer && transformer(domElement, reactChildren);

  if (transformedComponent) {
    return transformedComponent;
  } else {
    index++;
    return React.createElement(
      tagName.toLowerCase(),
      { ...getReactAttributes(attributes), key: `${tagName}-${index}` },
      ...reactChildren,
    );
  }
}
