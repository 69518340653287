import React from 'react';
import styled, { css } from 'styled-components';
import { UserIcon } from 'components/common/icons';
import { usePrintHandlers } from 'hooks/usePrintHandlers';
import {
  Author,
  CleContributor,
  ContentfulContributor,
  ContentfulCurrentAwarenessAuthorsCollection,
  ContentfulKnowHowDocumentAuthorsCollection,
  ContentfulPublicationEditionChapterOriginalAuthorsCollection,
  ContentfulPublicationEditionChapterUpdateAuthorsCollection,
  ContentfulPublicationEditionOriginalAuthorsCollection,
  ContentfulPublicationEditionUpdateAuthorsCollection,
  Maybe,
} from '__generated__/graphql';
import { AuthorHeadshot } from './AuthorHeadshot';
import { AuthorName } from './AuthorName';

type AuthorCollection =
  | ContentfulKnowHowDocumentAuthorsCollection
  | ContentfulCurrentAwarenessAuthorsCollection
  | ContentfulPublicationEditionUpdateAuthorsCollection
  | ContentfulPublicationEditionOriginalAuthorsCollection
  | ContentfulPublicationEditionChapterUpdateAuthorsCollection
  | ContentfulPublicationEditionChapterOriginalAuthorsCollection
  | { items: Maybe<CleContributor | ContentfulContributor | Author>[] };

interface AuthorNamesProps {
  authorsCollection?: Maybe<AuthorCollection> | null;
  hideIcon?: boolean;
  headshotWidth?: number;
}

export function AuthorNames({
  authorsCollection,
  hideIcon = false,
  headshotWidth,
}: AuthorNamesProps) {
  const [isPrinting] = usePrintHandlers();
  const authors = React.useMemo(
    () => [...(authorsCollection?.items || [])].filter(Boolean),
    [authorsCollection],
  );

  return (
    <StyledAuthorNames
      className="authors"
      $hideIcon={hideIcon}
      $headshotWidth={headshotWidth}
    >
      <dt>
        <UserIcon
          height={24}
          width={24}
          path={isPrinting ? '#000000' : 'rgba(31, 34, 106, 0.8)'}
          circle={isPrinting ? '#ffffff' : '#d8d8d8'}
          className="icon"
        />
      </dt>
      {authors.map((author, i) => (
        <React.Fragment key={`${author?.webDisplayName}-${i}`}>
          <AuthorHeadshot author={author} headshotWidth={headshotWidth} />
          <AuthorName author={author} index={i} totalAuthors={authors.length} />
        </React.Fragment>
      ))}
    </StyledAuthorNames>
  );
}

interface StyledAuthorNamesProps {
  $hideIcon: boolean;
  $headshotWidth?: number;
}

const StyledAuthorNames = styled.dl<StyledAuthorNamesProps>`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  > dd,
  > dt {
    flex: 0 1 auto;
  }
  &:last-child {
    text-align: right;
  }

  .icon {
    ${({ $hideIcon }) => ($hideIcon ? 'display: none;' : null)}
  }
  dt {
    vertical-align: -7px;
  }
  dt,
  dd {
    display: inline-block;
    .conjoiner {
      margin: 0 4px;
    }
    .separator {
      margin: 0 4px 0 0;
    }
  }
  dt + dd {
    margin-left: ${({ $hideIcon, $headshotWidth }) =>
      $hideIcon && !$headshotWidth ? 0 : '6px'};
  }
`;
