import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function useQueryString() {
  const [searchParams] = useSearchParams();

  const queryString = useMemo(() => {
    const params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  }, [searchParams]);

  return queryString;
}

export default useQueryString;
