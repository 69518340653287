import React from 'react';
import styled from 'styled-components';
import {
  Author,
  CleContributor,
  ContentfulContributor,
  Maybe,
} from '__generated__/graphql';

interface AuthorHeadshotProps {
  author: Maybe<CleContributor | ContentfulContributor | Author>;
  headshotWidth?: number;
}

export function AuthorHeadshot({ author, headshotWidth }: AuthorHeadshotProps) {
  if (!headshotWidth || !author) {
    return null;
  }

  const headshotUrl =
    'headshotUrl' in author ? author.headshotUrl : author.headshot?.url;

  if (!headshotUrl) {
    return null;
  }

  return (
    <StyledAuthorHeadshot
      className="headshot"
      $headshotWidth={headshotWidth || 24}
    >
      <img src={headshotUrl} alt={`${author.webDisplayName} headshot`} />
    </StyledAuthorHeadshot>
  );
}

const StyledAuthorHeadshot = styled.dt<{ $headshotWidth: number }>`
  border-radius: ${({ $headshotWidth }) => $headshotWidth}px;
  overflow: hidden;
  width: ${({ $headshotWidth }) => $headshotWidth}px;
  img {
    display: block;
    line-height: 1;
    max-width: 100%;
  }
`;
